* { box-sizing: border-box }
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	font-family: "Roboto", sans-serif;
	font-size: 20px;

	color: #363636;

	&.error {
		animation: errorFade ease-in-out 1s;
	}
}

body {
	width: 100%;
	height: 100%;
}

button,
img,
a {
	user-select: none;
}

.app_wrapper {
	width: 100%;
	min-height: 100vh;

	padding: 8px;

	padding: 88px 12px 24px 12px;

	background-color: #deeeff;
	position: relative;

	.loader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($color: #000, $alpha: 0.25);
		cursor: wait;

		display: flex;
		justify-content: center;
		align-items: center;

		&.showing {
			animation: fadeIn 0.16s ease-in-out;
		}
		&.shown {
			opacity: 1;
			display: flex;
		}

		&.hiding {
			animation: fadeOut 0.16s ease-in-out;
		}
		&.hidden {
			opacity: 0;
			display: none;
		}

		> div {
			filter: drop-shadow(0px 0px 4px #000);
			width: 240px;
		}
	}

	.page {
		width: 100%;
		min-height: calc(100vh - 112px);

		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: column;
	}

	.gradient_bar_top {
		width: 100%;
		position: fixed;
		height: 88px;
		top: 0;
		left: 0;
		right: 0;
		background: rgb(222, 238, 255);
		background: linear-gradient(
			180deg,
			rgba(222, 238, 255, 1) 0%,
			rgba(222, 238, 255, 1) 54%,
			rgba(222, 238, 255, 0.5494572829131652) 85%,
			rgba(222, 238, 255, 0) 100%
		);
	}
	.gradient_bar_bottom {
		width: 100%;
		position: fixed;
		height: 24px;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgb(222, 238, 255);
		background: linear-gradient(
			0deg,
			rgba(222, 238, 255, 1) 0%,
			rgba(222, 238, 255, 1) 54%,
			rgba(222, 238, 255, 0.5494572829131652) 85%,
			rgba(222, 238, 255, 0) 100%
		);
	}

	.home_icon {
		position: fixed;
		top: 16px;
		left: 16px;
		width: 56px;
		height: 56px;
		border-radius: 28px;
		background-color: #fff;
		padding: 4px;
		border: 2px solid #deeeff;
	}

	.profile_container {
		position: fixed;
		top: 16px;
		right: 16px;
		background-color: #fff;
		padding: 4px;
		border-radius: 28px;
		overflow: hidden;
		user-select: none;
		border: 2px solid #deeeff;

		max-width: 56px;
		max-height: 56px;
		transition: ease-in-out all 0.16s;

		&:hover {
			max-width: 90%;
			max-height: 110px;
		}

		.address_line {
			display: flex;
			flex-flow: row-reverse;

			img {
				width: 44px;
				height: 44px;
			}

			p {
				line-height: 48px;
				padding: 0 8px 0 16px;
			}
		}

		.logout {
			text-decoration: none;
			display: block;
			width: 100%;
			height: 48px;
			border-radius: 24px;
			border: none;
			cursor: pointer;
			margin-top: 2px;
			text-align: center;
			line-height: 48px;
			background-color: #f2f2f2;
		}
	}

	.content_card {
		background-color: #ffffff;
		box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
		width: 100%;
		/*height: 100%;*/
		border-radius: 24px;
		min-height: 160px;
		position: relative;

		/*transition: all .16s ease-in-out;*/

		h2 {
			width: 100%;
			padding: 0px 0 12px 0;
			font-size: 24px;
			text-align: left;
			line-height: 32px;
			user-select: none;

			.backlink {
				display: block;
				float: left;
				background: url(./assets/back-arrow.svg);
				width: 32px;
				height: 32px;
				background-repeat: no-repeat;
				background-position: left center;
			}
		}

		&.login ~ .profile_container {
			display: none;
		}
		&.login ~ .home_icon {
			display: none;
		}
		&.login {
			padding: 16px;
		}

		&.forgot_pass  {
			padding: 16px;

			form {
				button {
					width: 100%;
					margin-top: 4px;
				}
			}
		}

		&.forgot_pass_message {
			
			padding: 24px;

			p {
				text-align: center;
			}
			

			button {
				height: 40px;
				border: none;
				border-radius: 4px;
				padding: 0 8px;
				line-height: 40px;
				width: 100%;
				cursor: pointer;

				margin: 16px 0 0px 0;
				user-select: none;
			}

		}

		form {
			width: 100%;
			height: 100%;
			padding: 8px 16px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			label {
				width: 100%;
				user-select: none;
				margin-bottom: 4px;
				font-size: 16px;
			}

			input {
				width: 100%;
				border: none;
				background-color: #f2f2f2;
				outline: none;
				border-radius: 4px;
				padding: 8px 12px;
				margin-bottom: 12px;
			}

			a {
				width: 50%;
				font-size: 16px;
				line-height: 40px;
				color: #0355ab;
				text-decoration: none;

				margin: 16px 0 8px 0;
			}

			button {
				height: 40px;
				border: none;
				border-radius: 4px;
				padding: 0 8px;
				line-height: 40px;
				width: 50%;
				cursor: pointer;

				margin: 16px 0 8px 0;
				user-select: none;
			}
		}

		&.main_selection {
			display: flex;
			justify-content: space-around;
			padding: 16px 0;
			user-select: none;
			width: auto;

			.divider {
				height: 160px;
				border-radius: 2px;
				width: 2px;
				background-color: rgba(54, 54, 54, 0.1);
			}

			.main_link {
				margin: 0 6px;
				display: block;
				width: 160px;
				height: 160px;
				border-radius: 8px;
				transition: all 0.16s ease-in-out;
				text-decoration: none;

				/*&:hover {
                    background-color: #deeeff65;
                }*/

				img {
					width: 160px;
					height: 128px;
				}

				p {
					text-align: center;
					text-decoration: none;
				}
			}
		}

		&.my_pickups {
			padding: 16px;

			.pickups_container {
				border-radius: 4px;
				border-top: 1px solid rgba(54, 54, 54, 1);
				border-bottom: 1px solid rgba(54, 54, 54, 1);

				.pickup {
					padding: 4px;
					border-bottom: 1px solid rgba(54, 54, 54, 0.1);
					border-top: 1px solid rgba(54, 54, 54, 0.1);
					border-radius: 4px;

					display: flex;
					justify-content: space-between;
					align-items: center;

					p {
						&.heading {
							font-weight: 500;
						}
						&:nth-child(1) {
							width: 108px;
						}
						&:nth-child(2) {
							width: 108px;
						}
						&:nth-child(3) {
							width: 120px;
						}
					}

					a {
						display: block;
						width: 24px;
						height: 24px;
						border: none;
						background: url(./assets/box-question.svg);
						background-repeat: no-repeat;
						background-position: center;
						cursor: pointer;

						&.heading {
							cursor: initial;
							background: none;
						}
					}
				}
			}

			.details_container {
				border-radius: 4px;
				border-top: 1px solid rgba(54, 54, 54, 1);
				border-bottom: 1px solid rgba(54, 54, 54, 1);
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				margin-bottom: -1px;

				.detail {
					width: 40%;
					margin: 8px 0;

					p:first-child {
						font-weight: 500;
					}
				}
			}

			.articles_container {
				border-radius: 4px;
				border-top: 1px solid rgba(54, 54, 54, 1);
				border-bottom: 1px solid rgba(54, 54, 54, 1);

				.article {
					margin-top: -1px;
					padding: 4px;
					border-bottom: 1px solid rgba(54, 54, 54, 0.1);
					border-top: 1px solid rgba(54, 54, 54, 0.1);
					border-radius: 4px;

					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.addNote {
					text-align: center;
					display: flex;
					margin-top: -1px;

					button {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 34px;
						border: none;
						background-color: transparent;
						cursor: pointer;

						img {
							height: calc(100% - 8px);
						}

					}

					input {
						width: calc(100% - 68px);
						outline: none;
						border: none;
						border-bottom: 1px solid rgba(54, 54, 54, 0.1);
						border-top: 1px solid rgba(54, 54, 54, 0.1);
						border-radius: 4px;
						padding: 0 4px 0 4px;

						transition: all ease-in-out 0.16s;
						
					}
					.act {
						display: none;
					}

					&.active {
						.init {
							display: none;
						}
						.act {
							display: inherit;
						}

						flex-wrap: nowrap;

						button {
							width: 34px;
						}

					}
				
				}
			}
		}

		&.return_step {
			padding: 4px 16px;
			min-height: 24px;
			margin: 4px 0;

			transition: all 0.4s ease-in-out;

			&.collapsed {
				&.form_heading {
                    position: fixed;
                    top: 12px;
                    height: 24px;
                    padding: 0 16px;
                    background-color: #ebf2f9;
                    z-index: 999;
                    width: auto;

					> h2 {
						text-align: center;
						color: #363636;
						opacity: 1;
                        line-height: 24px;
                        opacity: 0.6;
                        font-size: 16px;
					}
				}

				.step_container {
					overflow: hidden;
					max-height: 0px !important;

					transition: all 0.4s ease-in-out;
				}

				h2 {
					padding: 0;
					opacity: 0.5;
					font-size: 20px;

					transition: all 0.4s ease-in-out;
				}
			}

			&.active {
				padding: 16px;

				h2 {
					padding: 0 0 12px 0;
					font-size: 24px;
					opacity: 1;

					transition: all 0.4s ease-in-out;
				}

				.step_container {
					overflow: hidden;

					transition: all 0.4s ease-in-out;
				}
			}

			.step_container .summary {
				display: flex;
				flex-flow: column;

				> .address,
				> .basic_info {
					margin-bottom: -1px;
					border-radius: 4px;
					border-top: 1px solid rgba(54, 54, 54, 1);
					border-bottom: 1px solid rgba(54, 54, 54, 1);
					padding: 8px 4px;
				}

				.label {
					font-weight: 500;
					padding-bottom: 4px;
				}

				.basic_info {
					width: 100%;
					display: flex;
					justify-content: space-between;
					margin-top: 1px;

					> div:nth-child(2) {
						text-align: center;
					}
					> div:nth-child(3) {
						text-align: right;
					}
				}

				.address {
					padding-bottom: 16px;

					.label {
						width: 100%;
						padding-bottom: 8px;
					}
				}

				.articles_list {
					padding: 0;

					.article {
						padding: 4px;
					}
				}

				.control_buttons {
					border: none;
				}
			}

			.control_buttons {
				display: flex;
				justify-content: space-between;
			}

			.step_move {
				border: none;
				margin-top: 16px;
				line-height: 48px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 16px;
				cursor: pointer;
				background: #f2f2f2;
				text-decoration: none;

				&.next_step {
					padding: 0 4px 0 12px;
				}
				&.prev_step {
					padding: 0 12px 0 4px;
				}
				&.last_step {
					float: right;
					padding: 0 12px 0 12px;

					img {
						margin-left: 8px;
						height: 24px;
					}
				}

				p {
					font-weight: 500;
				}

				&.next_step img {
					transform: rotate(180deg);
				}
			}

			.parcel_quans {
				border-radius: 4px;
				border-top: 1px solid rgba(54, 54, 54, 1);
				border-bottom: 1px solid rgba(54, 54, 54, 1);

				p {
					line-height: 48px;

					input {
						text-align: center;
						border: none;
						background-color: #f2f2f2;
						height: 32px;
						border-radius: 4px;
						margin-left: 4px;
						outline: none;

						transition: all ease-in-out 0.16s;
					}
				}
			}

			.pickup_date {
				border-radius: 4px;
				border-top: 1px solid rgba(54, 54, 54, 1);
				border-bottom: 1px solid rgba(54, 54, 54, 1);

				p {
					line-height: 48px;

					select {
						text-align: center;
						border: none;
						background-color: #f2f2f2;
						height: 32px;
						border-radius: 4px;
						outline: none;
						margin-left: 4px;

						transition: all ease-in-out 0.16s;

						cursor: pointer;
					}
				}

				p.summary {
					text-align: center;
				}
			}

			.address_form {
				border-radius: 4px;
				border-top: 1px solid rgba(54, 54, 54, 1);
				border-bottom: 1px solid rgba(54, 54, 54, 1);

				padding: 16px 8px;

				.field {
					width: 100%;
					margin-bottom: 8px;

					label {
						width: 100%;
						font-size: 16px;
					}

					input {
						width: 100%;
						background-color: #f2f2f2;
						border: none;
						height: 42px;
						padding: 0 8px;
						border-radius: 8px;

						transition: all ease-in-out 0.16s;
					}
				}

				.dual_line {
					display: flex;
					justify-content: space-between;

					.field {
						width: 47%;
					}
				}
			}

			.articles_list {
				border-radius: 4px;
				border-top: 1px solid rgba(54, 54, 54, 1);
				border-bottom: 1px solid rgba(54, 54, 54, 1);

				.article {
					border-bottom: 1px solid rgba(54, 54, 54, 0.1);
					border-top: 1px solid rgba(54, 54, 54, 0.1);
					border-radius: 4px;

					display: flex;
					justify-content: space-between;
					align-items: center;
					//overflow: hidden;

					transition: all ease-in-out 0.16s;

					/*animation: growOut 0.35s ease-in-out;*/

					div.basic-single {
						width: calc(100% - (24px + 50px));
						outline: none;
						height: 36px;
						border: none;
						background-color: transparent;
						cursor: pointer;
						outline: none;

						.select__control {
							background-color: transparent;
							border: none;
							outline: none !important;
							box-shadow: none !important;
						}

						.select__menu {
							width: 488px;
						}

						//background: url(./assets/chevron-down.svg);
						//background-repeat: no-repeat;
						//background-position: 4px center;
						//background-size: 24px;
						//padding-left: 32px;
					}

					input {
						border: none;
						outline: none;
						background-color: transparent;
						padding: 8px;
						border-right: 1px solid black;
						border-left: 1px solid black;

						-webkit-appearance: none;
						appearance: none;

						text-align: right;
						width: 52px;
						padding-right: 4px;

                        &.special_output_input  {
                            width: calc(100% - 40px);
                            text-align: left;
                            border-left: none;
                        }

					}

					button {
						width: 24px;
						height: 24px;
						margin: 8px;
						border: none;
						cursor: pointer;

						background: url(./assets/cross.svg);
						background-repeat: no-repeat;
					}

					&.add {
						button {
							margin: 4px;
							width: 100%;
							height: 24px;
							background: none;
							line-height: 24px;
							display: flex;
							justify-content: center;
							align-items: center;

							img,
							p {
								margin: 0 2px;
								height: 24px;
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}

.address_form {
	border-radius: 4px;
	border-top: 1px solid rgba(54, 54, 54, 1);
	border-bottom: 1px solid rgba(54, 54, 54, 1);

	padding: 16px 8px;

	.field {
		width: 100%;
		margin-bottom: 8px;

		label {
			width: 100%;
			font-size: 16px;
		}

		input {
			width: 100%;
			background-color: #f2f2f2;
			border: none;
			height: 42px;
			padding: 0 8px;
			border-radius: 8px;

			transition: all ease-in-out 0.16s;
		}
	}

	.dual_line {
		display: flex;
		justify-content: space-between;

		.field {
			width: 47%;
		}
	}
}

.animation_container.failed {

	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;

	> div, > div > div {
		height: 168px;
	}
	p {
		text-align: center;

		&.primary {
			font-weight: 500;
		}
		&.secondary {
			opacity: 0.4;
			margin-bottom: 24px;
		}
	}

	button {
		padding: 12px 32px;
		border: none;
		cursor: pointer;
		border-radius: 48px;
		border: 2px solid #6b94bf75;
		background: #badbff63;
	}
}

.content_card.search_form {
	min-height: auto;
	margin-bottom: 24px;
	padding: 8px 0;
}
.content_card.search_result {
	min-height: auto;
	margin-bottom: 12px;
	padding: 8px 16px 16px 16px;

	h3 {
		width: 100%;
		text-align: center;
		margin-bottom: 8px;
	}

	.info_cluster {
		width: 100%;
		display: flex;
		justify-content: space-between;

		> div {
			width: 50%;
		}
	}

	.contents, .parcels {
		margin-top: 4px;
		padding-top: 4px;
		border-top: 2px solid #f2f2f2;

		span:last-child {
			display: none;
		}
	}
}

#pickupSearchForm {
	display: flex;
	justify-content: space-between;
	align-items: center;

	input {
		width: 75%;
		margin: 0;
	}
	button {
		width: 24%;
		margin: 0;
	}
}


@media (max-width: 600px) {
	.app_wrapper .content_card.main_selection {
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		gap: 16px;
		width: 176px;
	}
	.app_wrapper .content_card.main_selection .divider {
		height: 2px;
		width: 100%;
	}
}

.page-enter .content_card,
.page-enter .animation_container {
	opacity: 0;
	transform: scale(1.1);
}

.page-enter-active .content_card,
.page-enter-active .animation_container {
	opacity: 1;
	transform: scale(1);
	transition: opacity 125ms, transform 125ms;
}

.page-exit .content_card,
.page-exit .animation_container {
	opacity: 1;
	transform: scale(1);
}

.page-exit-active .content_card,
.page-exit-active .animation_container {
	opacity: 0;
	transform: scale(0.95);
	transition: opacity 125ms, transform 125ms;
}

.pulsate {
	animation: 0.8s pulsate forwards ease-in-out;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		display: block;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
	}
}

@keyframes pulsate {
	0% {
		scale: 1;
	}
	35% {
		scale: 1.15;
	}
	50% {
		scale: 1;
	}
	65% {
		scale: 1.05;
	}
	100% {
		scale: 1;
	}
}

@keyframes errorFade {
	0% {
		background-color: #f2f2f2;
	}
	15% {
		background-color: #ffa6a6;
	}
	100% {
		background-color: #f2f2f2;
	}
}
